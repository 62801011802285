<template>
  <CModal :show.sync="showModal" :no-close-on-backdrop="true" :centered="true" size="lg" color="primary">
    <template #header>
      <h2 class="modal-title">Booking Requested - #{{ booking.id }}</h2>
      <CButtonClose @click="finish()" class="text-white" />
    </template>

    <CRow>
      <CCol sm="12">
        <p>Thank you for booking your bike(s) with us.</p>
        <p>The bike(s) have been reserved for you. Please collect the bicycle keys within 15 minutes at the front desk
          and enjoy your time on the bike!</p>
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="finish()" color="success">Finish</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "Success",
  props: {
    data: {},
    show: true,
  },
  data() {
    return {
      booking: {},
      showModal: false,
    };
  },
  created: function () { },
  methods: {
    finish() {
      this.$emit("finish");
    },
  },
  watch: {
    show: function (newVal) {
      this.showModal = newVal;
    },
    data(val) {
      this.booking = val;
    },
  },
};
</script>

<style scoped>
</style>
