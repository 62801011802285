<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>

          <CCol sm="12">
            <h1 class="text-center">Rent Your Bike</h1>
          </CCoL>

          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Enter Your Details</CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>
              <CCardBody v-if="location.mews_access_token && location.mews_service_id">
                <CRow>
                  <CCol sm="4">
                    <CInput name="mews_last_name" label="Last Name" placeholder="Enter your last name"
                      v-model="mews_last_name" required />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="mews_email" label="Email" placeholder="Enter your email" v-model="mews_email" required
                      description="Your e-mail that was registered at the hotel." />
                  </CCol>

                </CRow>

                <CRow>
                  <CCol sm="4">
                    <CButton variant="outline" type="submit" color="success" @click="getMewsCustomer()">
                      <CIcon name="cil-check" v-if="booking.mews_customer_id.length" />Check
                    </CButton>
                  </CCol>
                </CRow>

              </CCardBody>
              <CCardBody v-else>
                <CRow>
                  <CCol sm="4">
                    <CInput name="email" label="Email" placeholder="Enter your email" v-model="booking.email" required
                      description="Your e-mail will only be used for the purpose of sending you the booking confirmation." />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="name" label="Name" placeholder="Enter your name" v-model="booking.name" required />
                  </CCol>
                  <CCol sm="4">
                    <label>Room</label>
                    <v-select v-model="booking.customer_id" :reduce="(customer) => customer.id" label="name"
                      :options="customers"></v-select>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Add Your Bikes</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <div class="additional-fields">
                  <CRow>
                    <CContainer v-for="(booking_row, index) in booking.booking_rows" :key="index"
                      id="bookingRowsContainer">
                      <CRow>
                        <CCol md="1" sm="1" class="close-btn-container d-flex align-items-center">
                          <div @click="deleteBookingRow(index)" class="">
                            <CIcon class="close-btn text-danger" name="cil-delete" />
                          </div>
                        </CCol>
                        <CCol md="2" sm="5">
                          <label class="datetime-label">From</label>
                          <datetime format="dd-MM-yyyy HH:mm" type="datetime" value-zone="UTC" zone="UTC"
                            placeholder="Date and time" label="From" v-model="booking_row.from"
                            v-on:input="getAvailableProducts(index)" auto disabled></datetime>
                        </CCol>

                        <CCol md="2" sm="5">
                          <label class="datetime-label">Till</label>
                          <datetime format="dd-MM-yyyy HH:mm" type="datetime" value-zone="UTC" zone="UTC"
                            placeholder="Date and time" v-model="booking_row.till"
                            v-on:input="getAvailableProducts(index)" auto></datetime>
                        </CCol>

                        <CCol md="3" sm="4">
                          <CSelect label="Bike" :options="booking_row.availableProducts"
                            :value.sync="booking_row.product_id" v-on:update:value="productSelected(booking_row)" />
                        </CCol>

                        <CCol md="2" sm="4">
                          <CInput label="Price" type="number" prepend="€" v-model="booking_row.price_inc" disabled />
                        </CCol>

                        <CCol sm="1">
                          <label for="" class="">Insurance</label><br />
                          <CSwitch label="Insurance" v-bind="{
                            variant: '3d',
                            shape: 'pill',
                            size: 'lg',
                          }" color="success" :checked.sync="booking_row.insurance" v-on:update:checked="doCalculations"
                            variant="outline" :disabled="!booking_row.insurance_optional" />
                        </CCol>
                      </CRow>
                    </CContainer>

                    <CCol sm="12" class="add-btn">
                      <CButton variant="outline" type="submit" color="success" @click="addBookingRow()">
                        <CIcon name="cil-playlist-add" />Add Bike
                      </CButton>
                    </CCol>
                  </CRow>
                </div>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Check The Summary</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CContainer>
                    <CRow>
                      <CCol sm="2">
                        <p>Price ex.</p>
                        € <strong>{{ summary.priceEx }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Tax</p>
                        € <strong>{{ summary.priceTax }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Price inc.</p>
                        € <strong>{{ summary.priceInc }}</strong>
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>
              </CCardBody>
            </CCard>

            <CRow class="terms-conditions-block">
              <CCol sm="4">
                <CButton variant="outline" type="submit" color="primary" @click="showTerms = true">
                  <CIcon name="cil-file" />Show Terms & Conditions
                </CButton>
              </CCol>
              <CModal title="Terms & Conditions" color="primary" :show.sync="showTerms">
                <span v-html="location.terms"></span>
                <template #footer>
                  <CButton @click="
                    showTerms = false;
                  booking.terms_agreed = true;
                  " color="success">Agree</CButton>
                  <CButton @click="
                    showTerms = false;
                  booking.terms_agreed = false;
                  " color="danger">Close</CButton>
                </template>
              </CModal>
            </CRow>
          </CCol>

          <CCol>
            <CButton type="submit" color="success" @click="requestBooking"
              :disabled="!booking.terms_agreed && !(booking.mews_customer_id || booking.customer_id)">
              <CIcon name="cil-check-circle" />Request My Booking
            </CButton>
          </CCol>

          <SuccessModal :show="showSuccessModal" :data="booking" @finish="finish()"></SuccessModal>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"

import MewsAPI from "/app/src/api/mews.js"
import BookingAPI from "/app/src/api/booking.js"
import CustomerAPI from "/app/src/api/customer.js"
import LocationAPI from "/app/src/api/location.js"
import ProductAPI from "/app/src/api/product.js"

import SuccessModal from "./SuccessModal"

export default {
  name: "Create",
  components: { Datetime, SuccessModal },
  data: () => {
    return {
      bookingAPI: new BookingAPI(),
      mewsAPI: new MewsAPI(),
      customerAPI: new CustomerAPI(),
      locationAPI: new LocationAPI(),
      productAPI: new ProductAPI(),
      customers: [],
      location: {},
      mews_last_name: "",
      mews_email: "",
      booking: {
        booking_rows: [],
        price_discount: 0,
        mews_customer_id: "",
        mews_customer_name: ""
      },
      products: [],
      summary: {
        priceEx: 0,
        priceTax: 0,
        priceInc: 0,
      },
      showTerms: false,
      showSuccessModal: false,
      mewsTypingTimeout: null
    }
  },
  created: function () {
    this.location = this.$store.getters.location
    this.booking.location_id = this.$route.params.id

    this.getLocationDetails(this.booking.location_id)
    this.getCustomers()
  },
  methods: {
    getLocationDetails(location_id) {
      console.log("get location")
      if (isNaN(location_id)) {
        this.$alert.show({ type: "danger", message: 'location does not exist' })
        this.$router.push({ path: "/" })
        return
      }
      this.$store.dispatch("loading")
      let self = this
      this.locationAPI
        .detail(location_id)
        .then((location) => {
          self.location = location
          this.$store.dispatch("stopLoading")
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
          this.$router.push({ path: "/" })
          return
        })
    },
    getAvailableProducts: function (index) {
      let row = this.booking.booking_rows[index]
      if (row.from != "" && row.till != "") {
        this.$store.dispatch("loading")
        let self = this
        this.productAPI
          .availableProducts(row.from, row.till, self.booking.location_id)
          .then((products) => {
            self.booking.booking_rows[index].product_id = ""
            var availableProducts = [
              {
                label: "Select Bike",
                value: "",
                product_id: "",
                price: 0,
              },
            ]
            for (let i = 0; i < products.length; i++) {
              availableProducts.push({
                label: products[i].type + ": #" + products[i].number,
                value: products[i].id,
                product_id: products[i].id,
                price: products[i].price,
              })
            }
            this.$set(
              this.booking.booking_rows[index],
              "availableProducts",
              availableProducts
            )
            self.$store.dispatch("stopLoading")
          })
          .catch((error) => {
            this.$store.dispatch("stopLoading")
            this.$alert.show({ type: "danger", message: error })
          })
      }
    },

    getCustomers: function () {
      console.log("get customers")
      this.$store.dispatch("loading")
      let self = this
      this.customerAPI
        .byLocationId(this.booking.location_id)
        .then((customers) => {
          this.$store.dispatch("stopLoading")
          self.customers = customers
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    getMewsCustomer: function () {
      if (!this.mews_last_name.length || !this.mews_email.length) {
        return
      }

      clearTimeout(this.mewsTypingTimeout)
      let self = this
      this.mewsTypingTimeout = setTimeout(function () {
        console.log("get mews customers")

        self.mewsAPI
          .customer(self.booking.location_id, self.mews_last_name, self.mews_email)
          .then((customer) => {
            self.$store.dispatch("stopLoading")
            self.booking.mews_customer_id = customer.Id
            self.booking.mews_customer_name = customer.FirstName + ' ' + customer.LastName
            self.booking.name = customer.LastName
            self.booking.email = customer.email
          })
          .catch((error) => {
            self.$store.dispatch("stopLoading")
            self.$alert.show({ type: "danger", message: "User information not found. Please contact the support desk." })
          })
      }, 1000)

      self.$store.dispatch("loading")

    },

    addBookingRow: function () {
      var fromDate = new Date()
      fromDate.setMinutes(fromDate.getMinutes() + 15)
      var tillDate = new Date()

      // Create the till date
      let [hours, minutes, seconds] = this.location.return_time.split(":")
      tillDate.setHours(hours)
      tillDate.setMinutes(minutes)
      tillDate.setSeconds(seconds)

      if (fromDate > tillDate) {
        tillDate.setDate(tillDate.getDate() + 1)
      }

      var pad = function (i) {
        return i < 10 ? "0" + i : i
      }

      var fY = fromDate.getFullYear()
      var fm = fromDate.getMonth() + 1
      var fD = fromDate.getDate()
      var fH = fromDate.getHours()
      var fM = fromDate.getMinutes()
      var fS = fromDate.getSeconds()
      var fromDateString =
        fY +
        "-" +
        pad(fm) +
        "-" +
        pad(fD) +
        "T" +
        pad(fH) +
        ":" +
        pad(fM) +
        ":" +
        pad(fS)

      var tY = tillDate.getFullYear()
      var tm = tillDate.getMonth() + 1
      var tD = tillDate.getDate()
      var tH = tillDate.getHours()
      var tM = tillDate.getMinutes()
      var tS = tillDate.getSeconds()
      var tillDateString =
        tY +
        "-" +
        pad(tm) +
        "-" +
        pad(tD) +
        "T" +
        pad(tH) +
        ":" +
        pad(tM) +
        ":" +
        pad(tS)

      this.booking.booking_rows.push({
        product_id: null,
        from: fromDateString,
        till: tillDateString,
        insurance: true,
        discount: 0,
      })
    },

    requestBooking: function () {
      if (!this.booking.terms_agreed) {
        this.$alert.show({
          type: "danger",
          message: "Terms & Conditions haven't been agreed to",
        })
        return
      }

      this.$store.dispatch("loading")

      this.bookingAPI
        .request(this.booking)
        .then((result) => {
          this.booking.id = result.id
          this.$store.dispatch("stopLoading")
          this.showSuccessModal = true
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    productSelected: function (booking_row) {
      // Get the selected product
      for (let i = 0; i < booking_row.availableProducts.length; i++) {
        if (
          booking_row.availableProducts[i].product_id == booking_row.product_id
        ) {
          let selected_product = booking_row.availableProducts[i]

          if (selected_product.price.insurance > 0) {
            booking_row.insurance = true
            booking_row.insurance_optional =
              selected_product.price.insurance_optional
          } else {
            booking_row.insurance = false
            booking_row.insurance_optional = false
          }
        }
      }

      this.doCalculations()
    },

    doCalculations: function () {
      this.calculateBookingRowPricing()
      this.calculateTotalPricing()
    },

    calculateBookingRowPricing: function () {
      // Update all price fields for all order rows
      for (var i = 0; i < this.booking.booking_rows.length; i++) {
        // Reset calculations of row
        this.booking.booking_rows[i].tax_percentage = 0
        this.booking.booking_rows[i].price_inc = 0
        this.booking.booking_rows[i].price_tax = 0
        this.booking.booking_rows[i].price_ex = 0

        // Check if availble products are already fetched. Otherwise price calculation is not necessary
        if (!this.booking.booking_rows[i].hasOwnProperty("availableProducts")) {
          continue
        }

        // Set Booking Row Price
        for (
          var j = 0;
          j < this.booking.booking_rows[i].availableProducts.length;
          j++
        ) {
          // Compare if the selected product is found
          if (
            this.booking.booking_rows[i].availableProducts[j].product_id ===
            this.booking.booking_rows[i].product_id
          ) {
            // Update the booking row pricing
            this.booking.booking_rows[i].tax_percentage =
              this.booking.booking_rows[i].availableProducts[
                j
              ].price.tax_percentage
            this.booking.booking_rows[i].price_inc =
              this.booking.booking_rows[i].availableProducts[j].price.inc

            // Add insurance, if selected
            if (this.booking.booking_rows[i].insurance) {
              this.booking.booking_rows[i].price_inc +=
                this.booking.booking_rows[i].availableProducts[
                  j
                ].price.insurance
            }

            // Set tax and ex price
            this.booking.booking_rows[i].price_tax = this.formatNumber(
              (this.booking.booking_rows[i].price_inc /
                (this.booking.booking_rows[i].tax_percentage + 100)) *
              this.booking.booking_rows[i].tax_percentage,
              2
            )
            this.booking.booking_rows[i].price_ex = this.formatNumber(
              this.booking.booking_rows[i].price_inc -
              this.booking.booking_rows[i].price_tax,
              2
            )
          }
        }
      }
    },

    calculateTotalPricing: function () {
      // Define summary totals
      this.summary.priceEx = 0.0
      this.summary.priceTax = 0.0
      this.summary.priceInc = 0.0

      // Update all price fields for all order rows
      for (var i = 0; i < this.booking.booking_rows.length; i++) {
        // Update totals
        this.summary.priceEx += this.booking.booking_rows[i].price_ex
        this.summary.priceTax += this.booking.booking_rows[i].price_tax
        this.summary.priceInc += this.booking.booking_rows[i].price_inc
      }

      this.summary.priceEx = this.formatNumber(this.summary.priceEx, 2)
      this.summary.priceTax = this.formatNumber(this.summary.priceTax, 2)
      this.summary.priceInc = this.formatNumber(this.summary.priceInc, 2)
    },

    deleteBookingRow: function (index) {
      this.booking.booking_rows.splice(index, 1)
      this.calculateTotalPricing()
    },

    formatNumber: function (val, decimals) {
      //Parse the value as a float value
      val = parseFloat(val)
      //Format the value w/ the specified number
      //of decimal places and return it.
      return parseFloat(val.toFixed(decimals))
    },

    finish: function () {
      this.booking = {
        location_id: this.$route.params.id,
        booking_rows: [],
        terms_agreed: false,
        mews_customer_id: ""
      }
      this.mews_last_name = ""
      this.mews_email = ""
      this.doCalculations()
      this.showSuccessModal = false
    },
  },
}
</script>

<style scoped>
.location-logo {
  height: 150px;
}

.close-btn-container {
  text-align: center;
}

.close-btn {
  margin-right: 0;
  margin-bottom: 0;
  margin-top: -2px;
  line-height: 14px;
}

.add-btn {
  margin-bottom: 20px !important;
}

.summary {
  margin-bottom: 20px;
}

#bookingRowsContainer .btn-outline-danger {
  margin-top: 32px;
  padding: 4px 8px;
}

#bookingRowsContainer div {
  padding: 2px;
}

.datetime-label {
  margin-bottom: 8px;
}

.c-switch {
  margin-top: 3px;
  margin-left: 5px;
}

.terms-conditions-block {
  margin-bottom: 20px;
}
</style>
